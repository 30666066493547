.our-team {
    padding: 50px 0px;
    background-color: #f9f9f9;
}
.our-team h2{
  margin-bottom: 40px;
}
.our-team a:hover{
  color: #333;
}
.img-fluids{
    height: 100%;
    width: 100%;
}
.client-img {
    width: 80px;
    height: 80px;
    overflow: hidden;
    margin: 0 auto;
    border-radius: 50%;
    border: 1px solid #607D8B;
}
.box-bg {
    background-color: #fff;
    padding: 20px 20px;
    border: 2px solid #e2e2e2;
    border-top-left-radius: 40px;
    border-bottom-right-radius: 40px;
    position: relative;
    margin: 15px 0px;
    overflow: hidden;
}
.details {
    padding: 10px 0px;
}
.details h4 {
    color: #607D8B;
}
.details h6 {
    color: #9E9E9E;
}
.details p {
    color: #777777;
    line-height: 20px;
    padding-top: 7px;
}
.details a {
    margin: 0 auto;
    font-size: 16px;
    color: #607D8B;
    text-decoration: none;
    font-weight: 500;
    display: table;
    margin-top: 20px;
}
.social-links {
    width: 100%;
    position: absolute;
    top: -200px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.6);
    height: 50%;
    padding: 30px 0px;
    transition: 1s;
}
.box-bg:hover .social-links{
  top: 0px;
}
.social-links ul {
    padding: 0px;
    margin: 0px;
}
.social-links ul li {
    list-style: none;
    float: left;
    width: 20%;
}
.social-links ul li a {
    text-align: center;
    display: block;
    background-color: #e6e6e6;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    line-height: 40px;
    color: #607D8B;
    margin: 0 auto;
}
.social-links ul li a:hover {
    font-size: 20px;
}
.social-links h4{
  color: #fff;
  padding-bottom: 15px;
}